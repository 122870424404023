import * as React from "react";
import PageComponent from "src/components/pages/learn/types-of-life-insurance";

const Page = ({ location }) => {
  const copy = (
    <div>
      <h2 className="text-blue font-bold mb-2 mt-4">TERM LIFE INSURANCE</h2>

      <p className="mb-4">
        As the name implies, this is life insurance for a term, or a set number
        of years. Most term policies offer a level premium guaranteed for
        10/15/20/25 or 30 years. At the end of the term the policy either ends
        or the premium jumps so high that virtually every policyholder lets the
        policy lapse. Think of term life insurance as similar to automobile
        insurance. It covers you in case of an unexpected collision (premature
        death). If you don’t have a collision (you don’t die during the term
        period) no benefit is paid out.
      </p>
      <p className="mb-4">
        Term life insurance will almost always give one the most death benefit
        for the lowest cost. It is best suited for temporary life insurance
        needs such as:
      </p>
      <ol className="list-decimal ml-4">
        <li>Family protection while children are young and dependent</li>
        <li>Covering a mortgage or other debt</li>
        <li>Funding a business buy/sell agreement</li>
        <li>The cost of replacing a key employee</li>
      </ol>

      <h2 className="text-blue font-bold mb-2 mt-8">
        PERMANENT LIFE INSURANCE
      </h2>
      <p className="mb-4">
        A permanent life insurance policy is designed to last for a lifetime.
        This can be accomplished through a number of various types of permanent
        life insurance contracts. Permanent life insurance is best suited for:
      </p>
      <ol className="list-decimal ml-4">
        <li>Estate liquidity needs, such as inheritance tax</li>
        <li>Permanent business buy/sell arrangements</li>
        <li>Inheritance equalization</li>
        <li>Charitable Legacy</li>
        <li>Extinguishing long term debt</li>
      </ol>

      <h3 className="font-bold mt-4 text-xl">
        Here are some of the most popular types of permanent life insurance:
      </h3>

      <h4 className="mt-2 text-blue font-bold text-lg">WHOLE LIFE INSURANCE</h4>
      <p className="mb-4">
        Whole life policies typically have a guaranteed lifetime premium that
        must be paid for the life of the policy. Part of the premium goes into a
        cash value account within the policy. The cash value belongs to the
        policy owner and can be borrowed (at interest) while the policy remains
        in force. If the policy is cancelled then the cash value is paid to the
        policy owner. Many whole life policies are designed to endow, i.e., have
        the cash value equal the original death benefit, at the insured’s age
        100. This “classic” whole life contract is designed as a self completing
        sinking fund, in that the fund which will equal 100% of the original
        death benefit at age 100 will pay that same 100% whenever death occurs.
      </p>
      <p className="mb-4">
        Many whole life policies are “participating” which means the company
        plans to declare and pay a dividend to the policy owner depending on the
        financial results of the company. Dividends are, in essence, a return of
        excess premium. Dividends are not guaranteed to be paid. Dividends, when
        paid, can be taken in cash, used to reduce premium, or used to buy more
        insurance within the policy.
      </p>

      <h4 className="mt-2 text-blue font-bold text-lg">
        UNIVERSAL LIFE INSURANCE
      </h4>
      <p className="mb-4">
        Like whole life insurance, universal life insurance is designed to last
        a lifetime, and part of the premium goes into a cash value account.
        While whole life insurance is somewhat rigid in its structure, universal
        life insurance promotes flexibility. Universal life insurance allows the
        policy owner to fund the policy in a manner which best suits the needs
        of the policy owner and beneficiaries. While a whole life insurance
        contract requires premiums be paid each year, universal life insurance
        allows the policy owner to fund the policy with a planned premium that
        can be changed or even stopped based on cash value returns and life
        expectancy anticipation. As long as the cost of insurance (COI) is
        deducted from the policy values each month, the full death benefit
        remains in effect. Universal life insurance is a very cost efficient way
        to purchase permanent death benefit but it does put more potential
        responsibility on the policy owner to understand and manage the policy
        as compared to whole life insurance.
      </p>
      <p className="mb-4">
        Recently, carriers have created universal life insurance with a no lapse
        guarantee (NLG) premium. This means that as long as the NLG premium is
        paid the policy remains in full effect beyond age 100. The policy owner
        still enjoys the premium payment flexibility that universal life
        insurance contains. NLG universal life insurance tends to have very
        little or no cash value build up, but it does give solid lifetime
        premium guarantees with the flexibility to lower or eliminate premium
        payments in reaction to life circumstances such as a determination that
        the insured’s life expectancy will not be to age 100 or beyond. NLG
        universal life insurance is a very cost efficient way to guarantee a
        lifetime death benefit without overpaying the insurance company.
      </p>

      <h4 className="mt-2 text-blue font-bold text-lg">
        WHOLE LIFE OR UNIVERSAL LIFE VARIATIONS
      </h4>

      <h5 className="mt-2 font-semibold">Variable Life</h5>
      <p className="mb-4">
        A Variable contract allows the cash value to be invested in mutual funds
        to attain a potentially higher return than a declared carrier return.
        This type of contract is considered an equity investment and falls under
        investing regulations.
      </p>

      <h5 className="mt-2 font-semibold">Indexed Life</h5>
      <p className="mb-4">
        Similar concept to variable but the cash value may only be invested in
        index funds (like S&amp;P 500). There is usually a cap, or maximum
        return that will be credited, but also a floor, or minimum, return
        credited. The policy will not necessarily capture the market high but
        will not incur a negative return.
      </p>
      <p className="mb-4">
        Both Variable and Index life are used primarily as a tax deferred
        investment vehicle in addition to death benefit protection.
      </p>
    </div>
  );
  return (
    <PageComponent
      lang="en"
      location={location}
      copy={copy}
      pageTitle="Types of Life Insurance"
    />
  );
};

export default Page;
